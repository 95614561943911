.page {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  .top-tags {
    bottom: auto;
    top: 35px;
  }
  
  .tags {
    color: #ebdbb2;
    opacity: 0.6;
    position: absolute;
    bottom: 0;
    left: 120px;
    font-size: 18px;
    font-family: 'La Belle Aurore', cursive;
  }
  
  .bottom-tag-html {
    margin-left: -20px;
  }
  
  .container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
  }

.container.about-page,
.container.contact-page,
.container.portfolio-page,
.container.resume-page {

    h1 {
      font-size: 53px;
      font-family: 'Coolvetica';
      color: #ebdbb2;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: -10px;
    }

    p {
      font-size: 13px;
      letter-spacing: 1.5px;
      color: #fbf1c7;
      font-family: sans-serif;
      font-weight: 300;
      min-width: fit-content;
      animation: pulse 1s;
  
      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }
      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }
    } 
    
    .text-zone {
      position: absolute;
      left: 10%;
      top: 50%;
      transform: translateY(-50%);
      width: 35%;
      vertical-align: middle;
      display: table-cell;
      max-height: 90%;
  }
  
  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }
}

@media screen and (max-width: 1200px) {
  .page {
    position: initial;
  }

  .container {
    position: initial;
    height: auto;
    min-height: auto;

    &.about-page,
    &.contact-page,
    &.resume-page,
    &.portfolio-page {
      .text-zone {
        position: initial;
        transform: none;
        width: 100%;
        display: block;
        padding: 20px;
        box-sizing: border-box;
      }
    }
  }
}