body {
    background-color: #282828;
}

.portfolio-page {
    padding-left: 100px;
    padding-right: 50px;
    width: calc(100% - 150px);
    position: initial;
    height: 100%;
    overflow: auto;

    h1.page-title {
        margin-left: 50px;
        margin-top: 100px;
    }

    h2 {
        color: #ebdbb2;
    }

    p {
        animation: none !important;
    }
}

.project-card {
    background-color: #282828;
    border-radius: 10px;
    padding: 20px;
    max-width: 400px;
    text-align: center;
    margin: 50px auto;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    overflow: visible;
    height: auto;
    min-height: auto;

    &.expanded {
        min-height: 600px;
    }
}

.project-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-content h2 {
    color: #ebdbb2;
    margin-bottom: 10px;
}

.card-content p {
    color: #ccc;
}

.no-scroll {
    overflow: hidden;
}

.calculator-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
}

.modal-content {
    background-color: #282828;
    padding: 20px;
    border-radius: 10px;
    max-width: 900px;
    width: 90%;
    height: 90%;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3);
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.modal-content h2 {
    color: #ebdbb2;
}

.modal-content p {
    color: #ccc;
    margin-bottom: 20px;
    flex-shrink: 0;
}

.close-button {
    position: absolute;
    top: 1px;
    right: 3px;
    background: none;
    border: none;
    font-size: 2em;
    color: #ebdbb2;
    cursor: pointer;
    transition: color 0.3s;
}

.close-button:hover {
    color: #fff;
}

.modal-content iframe {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    border: none;
    overflow: hidden;
}

.tooltip {
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.9em;
    margin-top: 10px;
    display: inline-block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 120px;
    text-align: center;
}

.calculator-container {
    margin-top: 30px;
    background-color: #282828;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1200px) {
    .portfolio-page {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;

        h1.page-title {
            margin-left: 20px;
            margin-top: 20px;
        }
    }

    .project-card {
        max-width: 100%;
        min-height: 100%;
        margin: 0;
        min-height: auto;
        height: auto;

        &.expanded {
            min-height: 600px;
        }
    }

    .modal-content {
        width: 95%;
        min-height: 600px;
        overflow: hidden;
        padding: 0;
    }

    .modal-content h2 {
        margin: 10px;
    }

    .modal-content p {
        margin: 10px;
    }
}
